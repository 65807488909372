import { defineStore } from 'pinia';
// import websiteInfo from '~/assets/json/website-info.json';

export const useWebsiteInfoStore = defineStore('websiteInfo', {
  state: () => ({
    website: {},
    footerArticles: [],
    partners: [],
  }),
  actions: {
    async fetchWebsiteInfo() {
      try {
        const response = await fetch(
          useNuxtApp().$apiUrl + '/api/website-info'
        );
        const responseJson = await response.json();
        this.website = responseJson.website;

        // const responseJson = websiteInfo;

        // // Get the hostname (domain)
        // const domain = window.location.hostname;

        // // Get the port
        // const port = window.location.port;

        // // Combine domain and port, but ignore port if it's 80
        // const domainWithPort =
        //   domain + (port && port !== '80' ? ':' + port : '');

        // this.website = responseJson.websites.find(
        //   (website) =>
        //     website.frontend_domain === domainWithPort ||
        //     website.dev_frontend_domain === domainWithPort
        // );
        this.footerArticles = responseJson.footer_articles;
        this.partners = responseJson.partners;
      } catch (error) {
        console.error('API call failed:', error);
      }
    },
    updateWebsiteInfoFromBroadcast(websites) {
      this.website = websites.find(
        (w) => w.coin_slug === this.website.coin_slug
      );
    },
  },
  getters: {
    websiteInfo: (state) => state.website,
  },
});
